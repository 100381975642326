import React from "react";
import { selectItemById, useGetItemsQuery } from "./itemsApiSlice";
import Item from "./Item";

const ItemsList = () => {
    const {
        data: items,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetItemsQuery('itemsList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <p>Loading...</p>;
    if (isError) {
        content = <p>Error: {error.message}</p>;
    }
    if (isSuccess) {

        const { ids } = items

        const list = ids?.length
            ? ids.map(itemId => <Item key={itemId} itemId={itemId} />)
            : null

        content = (
            <>
                <ul>
                    {list}
                </ul>
            </>
        )
    }

    return content;
};

export default ItemsList;