import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectItemById } from './itemsApiSlice'
import EditItemForm from './EditItemForm'

const EditItem = (props) => {
    const { id } = useParams()

    const item = useSelector(state => selectItemById(state, id))

    const content = item ? <EditItemForm item={item} setPath={props.setPath} /> : <p>Loading item...</p>

    return content
}
export default EditItem