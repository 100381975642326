import React from 'react'
import { useEffect, useState, useRef } from "react";
import ItemsList from './ItemsList';
import { useNavigate } from 'react-router-dom'


export default function ItemControlPanel(props) {
    
    useEffect(() => {
        props.setPath(window.location.pathname);
    }, []);

    const navigate = useNavigate()
    const handleNavigate = () => navigate('/itemcp/new')

    return ( 
        <div className="projects">

            <h1>Control Panel - Items</h1>
            <button onClick={handleNavigate}>ADD</button>

            <ItemsList />

        </div>
    )
}