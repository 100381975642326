import { useEffect, useState, useRef } from "react";
import { Routes, Route, Link, useNavigate, Outlet } from "react-router-dom";
import Home from './components/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from "./components/About";
import Login from "./features/auth/Login";
import UserControlPanel from "./features/users/UserControlPanel";
import NewUserForm from "./features/users/NewUserForm";
import EditUser from "./features/users/EditUser";
import ItemControlPanel from "./features/items/ItemControlPanel";
import NewItemForm from "./features/items/NewItemForm";
import EditItem from "./features/items/EditItem";
import Error404 from "./components/Error404";
import Prefetch from "./features/auth/Prefetch";
import PrefetchItems  from "./features/auth/PrefetchItems";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import { ROLES } from './config/roles'

function App() {
  
  const [showMenu, setShowMenu] = useState(false);
  const [path, setPath] = useState("");

  function toggleMenu () {
    setShowMenu(prevShowMenu => !prevShowMenu);
  }

  useEffect(() => {
    setPath(window.location.pathname);
}, []);

  return (
    <div className="App">
      <Navbar showMenu={showMenu} toggleMenu={toggleMenu} path={path} setPath={setPath} />
      <div className="main">
        <Routes>
          <Route path="/" element={<PrefetchItems />}>
            {/* PUBLIC ROUTES */}
            <Route index element={<Home setPath={setPath} />} />
            <Route path="about" element={<About setPath={setPath}/>} />
            <Route path="login" element={<Login setPath={setPath} />} />

            {/* PROTECTED ROUTES */}
            <Route element={<PersistLogin />}>
              <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Moderator]} />}>
                <Route element={<Prefetch />}>

                  <Route path="itemcp">
                    <Route index element={<ItemControlPanel setPath={setPath}/>} />
                    <Route path="new" element={<NewItemForm setPath={setPath}/>} />
                    <Route path=":id" element={<EditItem setPath={setPath}/>} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                    <Route path="usercp">
                      <Route index element={<UserControlPanel setPath={setPath}/>} />
                      <Route path="new" element={<NewUserForm setPath={setPath}/>} />
                      <Route path=":id" element={<EditUser setPath={setPath}/>} />
                    </Route>
                  </Route>

                </Route>
              </Route>
            </Route>
            {/* END - PROTECTED ROUTES */}

            <Route path='*' element={<Error404 />} />
          </Route>
          
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
