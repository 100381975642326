import React from 'react'
import { useSelector } from 'react-redux'
import { selectItemById } from './itemsApiSlice'
import { useNavigate } from 'react-router-dom'
import { memo } from 'react'

const Item = (props) => {

    const item = useSelector(state => selectItemById(state, props.itemId))

    const navigate = useNavigate()

    if (item) {
        const handleEdit = () => navigate(`/itemcp/${props.itemId}`)

        return (
            <li className="">
                Title: {item.title} --- Ingredients: {item.ingredients} --- Active: {item.active ? "YES" : "NO"}
                <button
                    className=""
                    onClick={handleEdit}
                >Edit
                </button>
            </li>
        )

    } else return null
}

const memoizedItem = memo(Item)

export default memoizedItem