import React from 'react'
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom"
import { useUpdateUserMutation, useDeleteUserMutation } from './usersApiSlice'

const USER_REGEX = /^[A-z]{3,20}$/
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/

const EditUserForm = (props) => {

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()

    const [deleteUser, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delError
    }] = useDeleteUserMutation()

    const navigate = useNavigate()

    const [username, setUsername] = useState(props.user.username)
    const [validUsername, setValidUsername] = useState(false)
    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [role, setRole] = useState(props.user.role)
    const [active, setActive] = useState(props.user.active)

    useEffect(() => {
        props.setPath(window.location.pathname);
    }, []);

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username))
    }, [username])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setUsername('')
            setPassword('')
            setRole('')
            navigate('/usercp')
        }
    }, [isSuccess, isDelSuccess, navigate])

    const onUsernameChanged = e => setUsername(e.target.value)
    const onPasswordChanged = e => setPassword(e.target.value)
    const onRoleChanged = e => setRole(e.target.value)
    const onActiveChanged = () => setActive(prev => !prev)

    let canSave
    if (password) {
        canSave = [role, validUsername, validPassword].every(Boolean) && !isLoading
    } else {
        canSave = [role, validUsername].every(Boolean) && !isLoading
    }

    const onSaveClicked = async (e) => {
        if (password) {
            await updateUser({ id: props.user.id, username, password, role, active })
        } else {
            await updateUser({ id: props.user.id, username, role, active })
        }
    }

    const onDeleteClicked = async () => {
        await deleteUser({ id: props.user.id })
    }

    const errClass = isError ? "errmsg" : "offscreen"
    const validUsernameClass = !username ? "form__input--incomplete" : ''
    const validPasswordClass = !password ? "form__input--incomplete" : ''
    const validRoleClass = !role ? "form__input--incomplete" : ''


    return (
        <>
            <p className={errClass}>{error?.data?.message}</p>
            <h1>Edit User: {username}</h1>
            <form className="form" onSubmit={e => e.preventDefault()}>
                <label className="" htmlFor="username">
                    Username: </label>
                <input
                    className={`${validUsernameClass}`}
                    id="username"
                    name="username"
                    type="text"
                    autoComplete="off"
                    value={username}
                    onChange={onUsernameChanged}
                />
                <label className="" htmlFor="password">
                    Password: </label>
                <input
                    className={`${validPasswordClass}`}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="off"
                    value={password}
                    onChange={onPasswordChanged}
                />
                <label className="" htmlFor="role">
                    Role: </label>
                <input
                    className={`${validRoleClass}`}
                    id="role"
                    name="role"
                    type="text"
                    autoComplete="off"
                    value={role}
                    onChange={onRoleChanged}
                />
                <label className="" htmlFor="active">
                    Active: </label>
                <input
                    className=""
                    id="active"
                    name="active"
                    type="checkbox"
                    checked={active}
                    onChange={onActiveChanged}
                />
                <button title="Save" onClick={onSaveClicked} disabled={!canSave}>Save</button>
                <button title="Delete" onClick={onDeleteClicked}>Delete</button>
            </form>
        </>
    )
}

export default EditUserForm