import { store } from '../../app/store'
import { itemsApiSlice } from '../items/itemsApiSlice'
import { usersApiSlice } from '../users/usersApiSlice';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Prefetch = () => {
    useEffect(() => {
        console.log('subscribing - items')
        const items = store.dispatch(itemsApiSlice.endpoints.getItems.initiate())

        return () => {
            console.log('unsubscribing - items')
            items.unsubscribe()
        }
    }, [])

    return <Outlet />
}
export default Prefetch