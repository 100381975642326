import React from 'react'
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom"
import { useAddNewItemMutation } from './itemsApiSlice'

const NewItemForm = (props) => {

    const [addNewItem, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewItemMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState('')
    const [ingredients, setIngredients] = useState('')
    const [price, setPrice] = useState('')

    useEffect(() => {
        props.setPath(window.location.pathname);
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setTitle('')
            setIngredients('')
            setPrice('')
            navigate('/itemcp')
        }
    }, [isSuccess, navigate])

    const onTitleChanged = e => setTitle(e.target.value)
    const onIngredientsChanged = e => setIngredients(e.target.value)
    const onPriceChanged = e => setPrice(e.target.value)

    const canSave = [title, ingredients, price].every(Boolean) && !isLoading

    const onSaveClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewItem({ title, ingredients, price })
        }
    }

    const errClass = isError ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validIngredientsClass = !ingredients ? "form__input--incomplete" : ''
    const validPriceClass = !price ? "form__input--incomplete" : ''


    return (
        <>
            <p className={errClass}>{error?.data?.message}</p>
            <h1>Add New Item</h1>
            <form className="form" onSubmit={onSaveClicked}>
                <label className="" htmlFor="title">
                    Title: </label>
                <input
                    className={`${validTitleClass}`}
                    id="title"
                    name="title"
                    type="text"
                    autoComplete="off"
                    value={title}
                    onChange={onTitleChanged}
                />
                <label className="" htmlFor="ingredients">
                    Ingredients: </label>
                <input
                    className={`${validIngredientsClass}`}
                    id="ingredients"
                    name="ingredients"
                    type="text"
                    autoComplete="off"
                    value={ingredients}
                    onChange={onIngredientsChanged}
                />
                <label className="" htmlFor="price">
                    Price: </label>
                <input
                    className={`${validPriceClass}`}
                    id="price"
                    name="price"
                    type="text"
                    autoComplete="off"
                    value={price}
                    onChange={onPriceChanged}
                />
                <button title="Save" disabled={!canSave}>Add</button>
            </form>
        </>
    )
}

export default NewItemForm