import React from 'react'
import { useEffect, useState, useRef } from "react";


export default function Error404() {
    
    return ( 
        <div className="error404">

            <h1>Error 404</h1>
            <h2>Page Not Found
            </h2>

        </div>
    )
}