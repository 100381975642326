import React from 'react'
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom"
import { useUpdateItemMutation, useDeleteItemMutation } from './itemsApiSlice'

const NewItemForm = (props) => {

    const [updateItem, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateItemMutation()

    const [deleteItem, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delError
    }] = useDeleteItemMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState(props.item.title)
    const [ingredients, setIngredients] = useState(props.item.ingredients)
    const [price, setPrice] = useState(props.item.price)
    const [active, setActive] = useState(props.item.active)

    useEffect(() => {
        props.setPath(window.location.pathname);
    }, []);

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setTitle('')
            setIngredients('')
            setPrice('')
            navigate('/itemcp')
        }
    }, [isSuccess, isDelSuccess, navigate])

    const onTitleChanged = e => setTitle(e.target.value)
    const onIngredientsChanged = e => setIngredients(e.target.value)
    const onPriceChanged = e => setPrice(e.target.value)
    const onActiveChanged = e => setActive(prev => !prev)

    const canSave = [title, ingredients, price].every(Boolean) && !isLoading

    const onSaveClicked = async (e) => {
        if (canSave) {
            await updateItem({ id: props.item.id, title, ingredients, price, active })
        }
    }
    const onDeleteClicked = async () => {
        await deleteItem({ id: props.item.id })
    }

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validIngredientsClass = !ingredients ? "form__input--incomplete" : ''
    const validPriceClass = !price ? "form__input--incomplete" : ''


    return (
        <>
            <p className={errClass}>{error?.data?.message}</p>
            <h1>Edit Item: {props.item.title}</h1>
            <form className="form" onSubmit={e => e.preventDefault()}>
                <label className="" htmlFor="title">
                    Title: </label>
                <input
                    className={`${validTitleClass}`}
                    id="title"
                    name="title"
                    type="text"
                    autoComplete="off"
                    value={title}
                    onChange={onTitleChanged}
                />
                <label className="" htmlFor="ingredients">
                    Ingredients: </label>
                <input
                    className={`${validIngredientsClass}`}
                    id="ingredients"
                    name="ingredients"
                    type="text"
                    autoComplete="off"
                    value={ingredients}
                    onChange={onIngredientsChanged}
                />
                <label className="" htmlFor="price">
                    Price: </label>
                <input
                    className={`${validPriceClass}`}
                    id="price"
                    name="price"
                    type="text"
                    autoComplete="off"
                    value={price}
                    onChange={onPriceChanged}
                />
                <label className="" htmlFor="active">
                    Active: </label>
                <input
                    className=""
                    id="active"
                    name="active"
                    type="checkbox"
                    checked={active}
                    onChange={onActiveChanged}
                />
                <button title="Save" onClick={onSaveClicked} disabled={!canSave}>Save</button>
                <button title="Delete" onClick={onDeleteClicked}>Delete</button>
            </form>
        </>
    )
}

export default NewItemForm