import React from 'react'
import { useEffect, useState, useRef } from "react";


export default function Footer() {
    
    return ( 
        <footer className="footer">
            <p>
                Copyright © {new Date().getFullYear()} | All rights reserved. Developed by Jure Kos.
            </p>
        </footer>
    )
}