import React from 'react'
import { useEffect, useState, useRef } from "react";


export default function Home(props) {
    
    useEffect(() => {
        props.setPath(window.location.pathname);
    }, []);

    return ( 
        <div className="home">

            <h1>Menu</h1>

            <div className="section">
                <div className="skillCards">
                    <div className="card">
                        <img src="/img/react.png" width="40px" height="auto" alt="react png"></img>
                        <h3>React.js</h3>
                        <p>Experience using React.js on multiple projects, using it to create responsive websites. Also some experience using <b>Angular</b>.</p>
                    </div>
                    <div className="card">
                        <img src="/img/htmlcssjs.png" width="100px" height="auto" alt="htmlcssjs png"></img>
                        <h3>HTML CSS JS</h3>
                        <p>Usage of basic website development languages to develop advance websites.</p>
                    </div>
                    <div className="card">
                        <img src="/img/mern.png" width="130px" height="auto" alt="mern png"></img>
                        <h3>MERN</h3>
                        <p>Using <b>MERN</b> (MongoDB, Express, React.js, Node.js) and <b>MEAN</b> (Angular) to create full stack web apps.</p>
                    </div>
                    <div className="card">
                        <img src="/img/sql.png" width="90px" height="auto" alt="sql png"></img>
                        <h3>SQL</h3>
                        <p>Writing SQL Queries, creating and managing SQL Databases.</p>
                    </div>
                </div>
            </div>


        </div>
    )
}