import React from 'react'
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom"
import { useAddNewUserMutation } from './usersApiSlice'

const NewUserForm = (props) => {

    const [addNewUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewUserMutation()

    const navigate = useNavigate()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('')

    useEffect(() => {
        props.setPath(window.location.pathname);
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setUsername('')
            setPassword('')
            setRole('')
            navigate('/usercp')
        }
    }, [isSuccess, navigate])

    const onUsernameChanged = e => setUsername(e.target.value)
    const onPasswordChanged = e => setPassword(e.target.value)
    const onRoleChanged = e => setRole(e.target.value)

    const canSave = [username, password, role].every(Boolean) && !isLoading

    const onSaveClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewUser({ username, password, role })
        }
    }

    const errClass = isError ? "errmsg" : "offscreen"
    const validUsernameClass = !username ? "form__input--incomplete" : ''
    const validPasswordClass = !password ? "form__input--incomplete" : ''
    const validRoleClass = !role ? "form__input--incomplete" : ''


    return (
        <>
            <p className={errClass}>{error?.data?.message}</p>
            <h1>Add New User</h1>
            <form className="form" onSubmit={onSaveClicked}>
                <label className="" htmlFor="username">
                    Username: </label>
                <input
                    className={`${validUsernameClass}`}
                    id="username"
                    name="username"
                    type="text"
                    autoComplete="off"
                    value={username}
                    onChange={onUsernameChanged}
                />
                <label className="" htmlFor="password">
                    Password: </label>
                <input
                    className={`${validPasswordClass}`}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="off"
                    value={password}
                    onChange={onPasswordChanged}
                />
                <label className="" htmlFor="role">
                    Role: </label>
                <input
                    className={`${validRoleClass}`}
                    id="role"
                    name="role"
                    type="text"
                    autoComplete="off"
                    value={role}
                    onChange={onRoleChanged}
                />
                <button title="Save" disabled={!canSave}>Add</button>
            </form>
        </>
    )
}

export default NewUserForm