import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import jwtDecode from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isUser = false
    let isModerator = false
    let isAdmin = false

    if (token) {
        const decoded = jwtDecode(token)
        const { username, role } = decoded.UserInfo

        console.log(role)
        isUser = role === 'User'
        isModerator = role === 'Moderator'
        isAdmin = role === 'Admin'

        return { username, role, isUser, isModerator, isAdmin }
    }

    return { username: '', role: '', isUser, isModerator, isAdmin }
}
export default useAuth