import React from "react";
import { selectUserById, useGetUsersQuery } from "./usersApiSlice";
import User from "./User";

const UsersList = () => {
    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetUsersQuery('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <p>Loading...</p>;
    if (isError) {
        content = <p>Error: {error.message}</p>;
    }
    if (isSuccess) {

        const { ids } = users

        const list = ids?.length
            ? ids.map(userId => <User key={userId} userId={userId} />)
            : null

        content = (
            <>
                <ul>
                    {list}
                </ul>
            </>
        )
    }

    return content;
};

export default UsersList;