import React from 'react'
import { useEffect, useState, useRef } from "react";


export default function About(props) {
    
    useEffect(() => {
        props.setPath(window.location.pathname);
    }, []);

    return ( 
        <div className="projects">

            <h1>About us</h1>

            <div className="project">
                <img src="/img/img8.png" alt="diploma png"></img>
                <div className="projInfo">
                    <h2>University Diploma - Trustworthy micro-credentials on Blockchain</h2>
                    <p style={{color:"rgb(170,170,170)"}}>Date: July 2023</p><br></br>
                    <p>The goal of diploma thesis is to research the use of blockchain within a web application, where professors will be able to create and assign microcredentials in the form of non-fungible tokens (NFTs) as proof of their students' skills.</p><br></br>

                    <div className="projSkills">
                        <a href="/downloads/Diploma.pdf" download>Diploma.pdf</a>
                        <a href="https://youtu.be/pcVsUvB4HQs"  target="blank">Video</a>
                        <a href="https://github.com/jurethekos/diploma"  target="blank">Code</a>
                    </div>
                    <div className="projSkills">
                        <div className="skillBtn">React.js</div>
                        <div className="skillBtn">Solidity</div>
                        <div className="skillBtn">Smart Contracts</div>
                    </div>
                </div>
            </div>

            <div className="project">
                <img src="/img/commingsoon.png" alt="comming soon png"></img>
                <div className="projInfo">
                    <h2 style={{color:"gray"}}>Full Stack Web App - COMMING SOON</h2>
                    <p style={{color:"rgb(170,170,170)"}}>Date: August 2023</p><br></br>
                    <p style={{color:"gray"}}>Comming soon</p><br></br>

                    <div className="projSkills">
                        <div className="skillBtn">React.js</div>
                        <div className="skillBtn">MERN</div>
                    </div>
                </div>
            </div>

            <div className="project">
                <img src="/img/commingsoon.png" alt="diploma png"></img>
                <div className="projInfo">
                    <h2 style={{color:"gray"}}>.NET C# App - COMMING SOON</h2>
                    <p style={{color:"rgb(170,170,170)"}}>Date: September 2023</p><br></br>
                    <p style={{color:"gray"}}>Comming soon</p><br></br>

                    <div className="projSkills">
                        <div className="skillBtn">.NET</div>
                        <div className="skillBtn">C#</div>
                    </div>
                </div>
            </div>

        </div>
    )
}