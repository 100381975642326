import React from 'react'
import { useSelector } from 'react-redux'
import { selectUserById } from './usersApiSlice'
import { useNavigate } from 'react-router-dom'
import { memo } from 'react'

const User = (props) => {

    const user = useSelector(state => selectUserById(state, props.userId))

    const navigate = useNavigate()

    if (user) {
        const handleEdit = () => navigate(`/usercp/${props.userId}`)

        return (
            <li className="">
                Name: {user.username} --- Role: {user.role}  --- Active: {user.active ? "YES" : "NO"}
                <button
                    className=""
                    onClick={handleEdit}
                >Edit
                </button>
            </li>
        )

    } else return null
}

const memoizedUser = memo(User)

export default memoizedUser