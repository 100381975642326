import React from 'react'
import { useEffect, useState, useRef } from "react";
import UsersList from './UsersList';
import { useNavigate } from 'react-router-dom'


export default function UserControlPanel(props) {
    
    useEffect(() => {
        props.setPath(window.location.pathname);
    }, []);

    const navigate = useNavigate()
    const handleNavigate = () => navigate('/usercp/new')

    return ( 
        <div className="projects">

            <h1>Control Panel - Users</h1>
            <button onClick={handleNavigate}>ADD</button>

            <UsersList />

        </div>
    )
}