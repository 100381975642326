import React from 'react'
import { useEffect, useState, useRef } from "react";
import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'

import { useSendLogoutMutation } from '../features/auth/authApiSlice';
import { selectCurrentToken } from '../features/auth/authSlice';

import useAuth from '../hooks/useAuth';


export default function Navbar(props) {


    //console.log(props.path);
    //console.log("Logged in: " + props.loggedIn);

    const navigate = useNavigate()
    const { pathname } = useLocation()
    const token = useSelector(selectCurrentToken)
    const { username, role, isAdmin, isModerator, isUser } = useAuth()

    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) {
            navigate('/')
        }
    }, [isSuccess, navigate])

    if (isLoading) return <p>Logging Out...</p>
    if (isError) return <p>Error: {error.data?.message}</p>

    const loginButton = (
        <Link onClick={() => {props.toggleMenu();}} className="navbarButton" to='/login'>Login</Link>
    )

    const logoutButton = (
        <button
            className="navbarButton"
            title="Logout"
            onClick={sendLogout}
        >
            Logout
        </button>
    )
    
    return ( 
        <div className="navbar">
            <div className={ props.showMenu ? "titleCombined menuBar" : "titleCombined"}>
                <img src="/img/koscloud.png" alt="logo" width="45" height="45"></img>
                <Link className="title" to="/">mernTemplate</Link>
                <button className="menuButton" onClick={props.toggleMenu}>
                    <div className="menuIcon"></div>
                </button>
            </div>
            
            <nav className={ props.showMenu ? "nav-show" : "nav-disable-mini"}>
                <Link onClick={() => {props.toggleMenu();}} className={props.path === "/" ? 'currentSite' : 'link'} to='/'>Menu</Link>
                <Link onClick={() => {props.toggleMenu();}} className={props.path === "/about" ? 'currentSite' : 'link'} to='/about'>About</Link>
                {(isAdmin || isModerator) && <Link onClick={() => {props.toggleMenu();}} className={props.path === "/itemcp" ? 'currentSite' : 'link'} to='/itemcp'>Items</Link>}
                {isAdmin && <Link onClick={() => {props.toggleMenu();}} className={props.path === "/usercp" ? 'currentSite' : 'link'} to='/usercp'>Users</Link>}
                { !token && loginButton }
                { token && logoutButton }
            </nav>

        </div>
    )
}